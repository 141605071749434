<template>
  <div class="diet-summary__container">
    <div class="diet-summary__content-wrapper">
      <div class="diet-summary__content-left">
        <div class="grid">
          <div>
            <p class="text">domyślna dieta:</p>
            <p class="text-bold">{{ this.variantName(this.variant) }}</p>
          </div>
          <div>
            <p class="text">ilość posiłków:</p>
            <p class="text-bold">{{ this.numberOfMeals }}</p>
          </div>
          <div>
            <p class="text">kaloryczność:</p>
            <p class="text-bold">{{ this.caloriesInDiet }} kcal</p>
          </div>
          <div>
            <p class="text">ilość dni:</p>
            <p class="text-bold">{{ this.dietLength }}</p>
          </div>
        </div>
      </div>
      <div class="diet-summary__content-right">
        <div>
          <div>
            <p class="text">cena dzienna:</p>
            <p class="text-bold">
              {{
                formatAmount(
                  this.$store.getters.getConfiguratorDayCost.beforeDiscount
                )
              }}
              zł
            </p>
          </div>
          <div>
            <p class="text">cena łączna:</p>
            <p class="text-bold">
              {{
                formatAmount(this.$store.getters.getConfiguratorDayCost.total)
              }}
              zł
            </p>
          </div>
        </div>
        <div @click="$emit('submitForm')" style="cursor: pointer">
          <img src="/img/dalej.png" alt="" />
        </div>
      </div>
    </div>
  </div>
  <!--  <div class="row">-->
  <!--    <div class="col-xs-12 price-for-day" style="margin-top: 40px">-->
  <!--      <p class="uppercase text-center" v-if="createOrderOnClick !== true">-->
  <!--        <span style="text-transform: none; font-size: 26px"-->
  <!--          >Cena za zestaw:&nbsp;&nbsp;</span-->
  <!--        >-->
  <!--        <span class="mobile-enter"></span>-->
  <!--        <span class="plg-ni_program_configurator-price">-->
  <!--          <span class="plg-ni_order_statistics">-->
  <!--            <span-->
  <!--              style="font-family: Helvetica; font-weight: bold; color: #222"-->
  <!--            >-->
  <!--              {{ dayCostAfterDiscount.toFixed(2).replace('.', ',') }} zł-->
  <!--            </span>-->
  <!--          </span>-->
  <!--        </span>-->
  <!--      </p>-->

  <!--      <div class="text-center bottomLg" v-else>-->
  <!--        <h4 class="mainTitleSmall text-uppercase">Cena</h4>-->

  <!--        <h4 class="mainTitleSmallReg text-uppercase">-->
  <!--          <strong class="plg-ni_order_register_configurator-dailyPrice">{{-->
  <!--            dayCostAfterDiscount.toFixed(2).replace('.', ',')-->
  <!--          }}</strong>-->
  <!--          zł dziennie /-->
  <!--          <strong class="plg-ni_order_register_configurator-totalPrice">{{-->
  <!--            (dayCostAfterDiscount * $store.getters.getConfiguratorDietLength)-->
  <!--              .toFixed(2)-->
  <!--              .replace('.', ',')-->
  <!--          }}</strong>-->
  <!--          zł łącznie-->
  <!--        </h4>-->
  <!--        <h5-->
  <!--          class="mainTitleSmallReg text-uppercase"-->
  <!--          v-if="$store.getters.getConfiguratorDiscountCode !== null"-->
  <!--        >-->
  <!--          rabat-->
  <!--          <strong-->
  <!--            class="plg-ni_order_register_configurator-discountDailyPrice"-->
  <!--            >{{ getDayDiscount().toFixed(2).replace('.', ',') }}</strong-->
  <!--          >-->
  <!--          zł dziennie /-->
  <!--          <strong-->
  <!--            class="plg-ni_order_register_configurator-discountTotalPrice"-->
  <!--            >{{-->
  <!--              (getDayDiscount() * $store.getters.getConfiguratorDietLength)-->
  <!--                .toFixed(2)-->
  <!--                .replace('.', ',')-->
  <!--            }}</strong-->
  <!--          >-->
  <!--          zł łącznie-->
  <!--          <p-->
  <!--            class="discountFrom text-center"-->
  <!--            v-if="$store.getters.getConfiguratorDiscountCodeReferer !== null"-->
  <!--          >-->
  <!--            <span-->
  <!--              class="plg-ni_order_register_configurator-recommendedFrom"-->
  <!--              style="font-size: 30px"-->
  <!--              >Dzięki poleceniu od-->
  <!--              {{ $store.getters.getConfiguratorDiscountCodeReferer }}</span-->
  <!--            >-->
  <!--          </p>-->
  <!--        </h5>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </div>-->
</template>

<script>
import { dietVariant } from '@/helpers'
import MarginMixin from '@/helpers/mixins'

export default {
  mixins: [MarginMixin],
  props: {
    createOrderOnClick: {
      type: Boolean,
      default: false
    },
    numberOfMeals: {
      type: Number
    },
    caloriesInDiet: {
      type: Number
    },
    dietLength: {
      type: Number
    },
    variant: {
      type: Number
    }
  },
  name: 'diet-price',
  methods: {
    variantName(id) {
      const variant = dietVariant(
        this.$store.getters.getConfiguratorVariantOptions,
        id
      )

      return variant.name
    }
  }
}
</script>
