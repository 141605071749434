<template>
  <div class="page-layout">
    <header-client-view></header-client-view>

    <main class="main" :class="{ 'expanded-navbar': addMarginToMain }">
      <div class="ajax-loader-start js-ajax-load"></div>
      <div class="container">
        <div>
          <div class="plg-ni_order_summary">
            <div class="order_summary">
              <header-h1 title="Podsumowanie"></header-h1>

              <table class="order_summary-table table bottomSm">
                <tr>
                  <td>Kaloryczność wybranych posiłków:</td>
                  <td>{{ order.totalSize }}</td>
                </tr>
                <tr>
                  <td>Wybrany pakiet:</td>
                  <td>{{ packageName }}</td>
                </tr>
                <tr>
                  <td>Wybrane posiłki:</td>
                  <td>{{ mealTypeNames }}</td>
                </tr>
                <tr>
                  <td>Domyślny wariant diety:</td>
                  <td>{{ variantName }}</td>
                </tr>

                <tr>
                  <td>Data pierwszej dostawy:</td>
                  <td>{{ firstDelivery }}</td>
                </tr>
                <tr>
                  <td>Dieta z weekendami:</td>
                  <td>{{ isWeekendsIncluded }}</td>
                </tr>
                <tr>
                  <td>Liczba dni:</td>
                  <td>{{ dietLength }}</td>
                </tr>
                <tr>
                  <td>Cena zestawu:</td>
                  <td>{{ bagPriceFormatted | defaultValue(0) }} zł</td>
                </tr>
                <tr>
                  <td>Cena:</td>
                  <td>{{ orderPriceFormatted | defaultValue(0) }} zł</td>
                </tr>
                <tr>
                  <td>Ze skarbonki:</td>
                  <td>
                    {{ moneyboxPriceFormatted | defaultValue(0) }}
                  </td>
                </tr>
                <tr v-if="order.discountCode && !order.discountCode.message">
                  <td>Rabat:</td>
                  <td
                    v-if="
                      order.discountCode.discountType ===
                      DISCOUNT_CODE_TYPE_AMOUNT()
                    "
                  >
                    {{ formatAmount(order.discountCode.discount) }}
                    zł
                  </td>
                  <td v-else>{{ order.discountCode.discount }}%</td>
                </tr>
              </table>

              <div class="text-center" style="margin-bottom: 10px">
                <h4 class="mainTitleSmallReg">
                  <span class="text-uppercase"
                    >Kwota do zapłaty:
                    <span class="order_summary_price"
                      >{{ priceToPayFormatted }}&nbsp;zł</span
                    ></span
                  >
                </h4>
                <p class="fv-nip" style="text-transform: uppercase">
                  Jeśli chcesz otrzymać fakturę uzupełnij NIP
                  <u
                    ><a target="_blank" href="/user/my-data"
                      >na swoim profilu</a
                    ></u
                  >
                </p>
              </div>

              <div class="agreement-container text-center m-b-40">
                <div class="order-agreement__checkbox-wrapper">
                  <div class="order-agreement__checkbox-center">
                    <label
                      class="checkbox order-agreement__checkbox"
                      @click="changeAgreement($event)"
                    >
                      <input
                        type="checkbox"
                        name=""
                        class="plg-ni_order_summary-acceptRules"
                      />
                      <span class="box"
                        ><i
                          class="fa fa-check"
                          v-show="agreement"
                          style="display: none"
                        ></i
                      ></span>
                    </label>

                    <p class="order-agreement__label">
                      Akceptuję
                      <a href="#" @click.stop.prevent="gotoAgreement"
                        >regulamin</a
                      >
                      serwisu
                    </p>
                  </div>
                </div>

                <div
                  class="subTitle text-danger text-center plg-ni_order_register_configurator-name-error"
                >
                  {{ error }}
                </div>
              </div>

              <div
                class="text-center order_summary-btnWrapper plg-ni_order_summary-payContainer"
              >
                <template v-if="priceToPay !== 0">
                  <button
                    class="btn btn-yellow plg-ni_order_summary-pay"
                    type="button"
                    :disabled="!agreement || paymentClicked"
                    @click="payOnline"
                  >
                    {{ onlineBtnName }}
                  </button>
                </template>

                <template v-else>
                  <button
                    class="btn btn-info"
                    type="button"
                    :disabled="!agreement"
                    @click="payMoneyBox"
                  >
                    <template v-if="order.priceAfterDiscount === 0">
                      Zamawiam
                    </template>
                    <template v-else> Zapłać punktami </template>
                  </button>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>

    <footer-client-view></footer-client-view>
  </div>
</template>
<script>
import HeaderClientView from '@/components/HeaderClientView'
import FooterClientView from '@/components/FooterClientView'
import * as types from '@/store/mutations_types'
import router from '@/router'
import * as RouteCollection from '@/router/route_collection'
import * as actionTypes from '@/store/actions_types'
import order from '@/services/api/order'
import routes from '@/services/api/routes'
import axios from '@/interceptor'
import AuthApi from '@/services/api/auth'
import store from '@/store'
import HeaderH1 from '@/components/HeaderH1'
import MarginMixin from '../../helpers/mixins'
import * as ActionTypes from '@/store/actions_types'
import { DISCOUNT_CODE_TYPE_AMOUNT } from '@/consts/discountCodeType'

export default {
  components: { HeaderH1, HeaderClientView, FooterClientView },
  mixins: [MarginMixin],
  name: 'confirm',
  props: {
    id: null
  },
  beforeRouteLeave(to, from, next) {
    const isGoingToCreate = to.name === RouteCollection.OrderCreate
    const isGoingToAddresses = to.name === RouteCollection.UserAddress
    const isGoingToThanks = to.name === RouteCollection.OrderThanks
    if (isGoingToCreate || isGoingToAddresses || isGoingToThanks) {
      next()
      return
    }
    const answer = window.confirm(
      'Czy na pewno chcesz opuścić stronę bez zapisania zmian?'
    )
    next(answer)
  },
  data() {
    return {
      routeCollection: RouteCollection,
      order: {
        priceAfterDiscount: 0,
        priceLeftToPay: 0,
        priceFromMoneybox: 0,
        shouldGetPriceFromMoneybox: 0,
        shouldGetPriceFromWallet: 0
      },
      payment: {},
      needFV: false,
      nipErrorText: null,
      nip: null,
      errors: {},
      paymentClicked: false,
      onlineBtnName: 'Płacę',
      redirectTimer: null
    }
  },
  async mounted() {
    this.clearState()
    await this.fetchOrder(this.id)
    await this.$store.dispatch(ActionTypes.FETCH_CLIENT_ADDRESSES)
    // this.$gtm.enable(true)

    const data = {
      name: `${this.packageName} ${this.variantName}`,
      price: this.bagPrice.toFixed(2),
      category: this.order.totalSize,
      id: this.productId,
      quantity: this.dietLength,
      variant: this.mealTypeNames,
      dimension1: this.isWeekendsIncluded,
      dimension3: this.packageName,
      dimension4: this.variantName
    }

    const coupon = this.order.discountCode
      ? this.order.discountCode.discount
      : ''

    if (this.cartDetails.skipSteps) {
      window.dataLayer.push({
        event: 'checkoutStep',
        dimension3: this.cartDetails.dimension3,
        dimension4: this.cartDetails.dimension4,
        dimension5: this.cartDetails.dimension5,
        dimension6: this.cartDetails.dimension6,
        metric2: this.cartDetails.metric2,
        ecommerce: {
          checkout: {
            actionField: { step: '1', option: this.cartDetails.coupon_code },
            products: this.cartDetails.products
          }
        }
      })

      window.dataLayer.push({
        event: 'checkoutStep',
        dimension3: this.cartDetails.dimension3,
        dimension4: this.cartDetails.dimension4,
        ecommerce: {
          checkout: {
            actionField: { step: '2' }
          }
        }
      })

      window.dataLayer.push({
        event: 'checkoutOption',
        dimension3: this.cartDetails.dimension3,
        dimension4: this.cartDetails.dimension4,
        ecommerce: {
          checkout_option: {
            actionField: {
              step: '2',
              option: this.differentWeekendAddress
                ? 'weekend_address'
                : 'standard_address'
            }
          }
        }
      })
    }

    window.dataLayer.push({
      event: 'checkoutStep',
      dimension3: this.cartDetails.dimension3,
      dimension4: this.cartDetails.dimension4,
      ecommerce: {
        checkout: {
          actionField: {
            step: '3',
            option:
              this.order.shouldGetPriceFromMoneybox > 0
                ? 'moneybox'
                : 'no_moneybox'
          }
        }
      }
    })
  },
  computed: {
    error: function () {
      if (this.errors[1]) {
        return this.errors[1]
      }

      return null
    },
    nipError() {
      if (this.errors.nip) {
        return this.errors.nip[1]
      }

      if (this.nipErrorText !== null) {
        return this.nipErrorText
      }

      return null
    },
    fv: function () {
      return this.$store.state.order.fv
    },
    agreement: function () {
      return this.$store.state.order.agreement
    },
    diet: function () {
      return this.order.diet
    },
    firstBag: function () {
      if (this.diet) {
        return this.diet.bags[0]
      }

      return null
    },
    mealTypeNames: function () {
      if (this.firstBag) {
        return this.firstBag.bagItems
          .map(function (bagItem) {
            return bagItem.mealType.name
          })
          .join(', ')
      }

      return null
    },
    packageName: function () {
      if (this.diet) {
        return this.diet.package.name
      }

      return null
    },
    variantName: function () {
      if (this.diet) {
        return this.diet.defaultVariant.name
      }

      return null
    },
    productId: function () {
      if (this.diet) {
        return `${this.diet.defaultVariant.id}${this.diet.package.id}`
      }

      return null
    },
    firstDelivery: function () {
      if (this.firstBag) {
        return this.$date(this.firstBag.deliveryDate).format('YYYY-MM-DD')
      }

      return null
    },
    isWeekendsIncluded: function () {
      return this.order.isWeekendsIncluded ? 'TAK' : 'NIE'
    },
    dietLength: function () {
      if (this.diet) {
        return this.diet.bags.length
      }

      return null
    },
    bagPrice: function () {
      if (this.firstBag) {
        return this.firstBag.priceAfterDiscount
      }

      return 0
    },
    bagPriceFormatted: function () {
      if (this.bagPrice) {
        return this.bagPrice.toFixed(2).replace('.', ',')
      }

      return null
    },
    orderPrice: function () {
      if (this.diet) {
        return this.order.priceAfterDiscount
      }

      return 0
    },
    orderPriceFormatted: function () {
      if (this.orderPrice) {
        return this.orderPrice.toFixed(2).replace('.', ',')
      }

      return null
    },
    moneyboxPrice: function () {
      return this.$store.getters.jwtDecoded.moneybox === null
        ? 0
        : this.$store.getters.jwtDecoded.moneybox
    },
    moneyboxPriceFormatted: function () {
      return (
        this.order.shouldGetPriceFromMoneybox +
        this.order.shouldGetPriceFromWallet
      )
        .toFixed(2)
        .replace('.', ',')
    },
    priceToPay: function () {
      if (this.order.priceLeftToPay > this.moneyboxPrice) {
        return this.order.priceLeftToPay - this.moneyboxPrice
      }

      return 0
    },
    priceToPayFormatted: function () {
      return this.priceToPay.toFixed(2).replace('.', ',')
    },
    cartDetails() {
      return this.$store.getters.getCartDetails
    },
    differentWeekendAddress() {
      return this.$store.getters.differentWeekendAddress
    }
  },
  methods: {
    DISCOUNT_CODE_TYPE_AMOUNT() {
      return DISCOUNT_CODE_TYPE_AMOUNT
    },
    gotoAgreement() {
      window.open('https://republikasmakoszy.pl/regulamin-serwisu')
    },
    payOnline: function () {
      // this.$gtm.trackEvent({ event: 'zaplac' })
      this.setOrderPaymentType('PAYMENT_TYPE_ONLINE')
        .then(response => {
          store.commit(types.RESET_CONFIGURATOR)
          // AuthApi.refresh()
          this.goPayu(this.id)
        })
        .catch(e => {
          if (e.response && e.response.data && e.response.data.errors) {
            this.errors = e.response.data.errors
          }

          this.handleError(e, false)
        })
      this.paymentClicked = true
    },
    payMoneyBox: function () {
      this.setOrderPaymentType('PAYMENT_TYPE_MONEYBOX')
        .then(response => {
          store.commit(types.RESET_CONFIGURATOR)
          AuthApi.refresh()
          router.push({
            name: RouteCollection.OrderThanks,
            params: { id: this.order.id }
          })
        })
        .catch(e => {
          if (e.response && e.response.data && e.response.data.errors) {
            this.errors = e.response.data.errors
          }

          this.handleError(e, false)
        })
    },
    setOrderPaymentType: function (paymentType) {
      this.validateNip()
      if (typeof this.nipError !== 'undefined' && this.nipError !== null) {
        return new Promise((resolve, reject) => {
          reject(this.nipError)
        })
      }
      let data = {
        needFV: this.needFV,
        nip: this.nip,
        paymentType: paymentType
      }

      return axios.patch(routes.setOrderPaymentType(this.id), {
        data: JSON.stringify(data)
      })
    },
    fetchOrder: function (id) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(actionTypes.FETCH_ORDER, { id: id }).then(
          order => {
            this.order = order
            resolve(order)

            if (this.order.paymentStatus.systemValue === 'ORDER_PAID') {
              router.push({ name: RouteCollection.OrderHistory })
            }
          },
          e => {
            this.handleError(e, false)
            router.push({ name: RouteCollection.OrderHistory })
            reject(e)
          }
        )
      })
    },
    goPayu: function (id) {
      order.fetchOrderPaymentDetails(id).then(
        response => {
          this.payment = response.data

          let timeleft = 3
          this.redirectTimer = setInterval(() => {
            this.onlineBtnName = timeleft--

            if (timeleft <= 0) {
              clearInterval(this.redirectTimer)
              window.location = this.payment.redirectUri
            }
          }, 1000)
        },
        e => {
          this.handleError(e, false)
        }
      )
    },
    changeFV(event) {
      event.preventDefault()
      event.stopPropagation()
      if (!this.paymentClicked) {
        this.needFV = !this.needFV
        this.$store.commit(types.ORDER_CHANGE_FV, !this.$store.state.order.fv)
      }
    },
    changeAgreement(event) {
      event.preventDefault()
      event.stopPropagation()
      if (!this.paymentClicked) {
        this.$store.commit(
          types.ORDER_CHANGE_AGREEMENT,
          !this.$store.state.order.agreement
        )
      }
    },
    clearState() {
      this.$store.commit(types.ORDER_CHANGE_FV, false)
      this.$store.commit(types.ORDER_CHANGE_AGREEMENT, false)
    },
    validateNip() {
      this.nipErrorText = null
      if (this.needFV === false || this.nip === null) {
        return
      }
      let nipWithoutDashes = this.nip && this.nip.replace(/-/g, '')
      let reg = /^[0-9]{10}$/
      if (reg.test(nipWithoutDashes) === false) {
        this.nipErrorText = 'Podaj prawidłowy numer NIP'
      } else {
        let digits = ('' + nipWithoutDashes).split('')
        let checksum =
          (6 * parseInt(digits[0]) +
            5 * parseInt(digits[1]) +
            7 * parseInt(digits[2]) +
            2 * parseInt(digits[3]) +
            3 * parseInt(digits[4]) +
            4 * parseInt(digits[5]) +
            5 * parseInt(digits[6]) +
            6 * parseInt(digits[7]) +
            7 * parseInt(digits[8])) %
          11

        if (!(parseInt(digits[9]) === checksum)) {
          this.nipErrorText = 'Podaj prawidłowy numer NIP'
        }
      }
    }
  }
}
</script>

<style scoped>
.container {
  max-width: 1280px;
}

.checkbox {
  cursor: pointer;
  font-size: 25px;
  font-family: Helvetica;
  color: #222;
  font-weight: normal;
  position: relative;
  padding-left: 45px;
  display: inline-block;
  margin-top: 13px;
  margin-bottom: 13px;
}

.checkbox > .box {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  vertical-align: middle;
  width: 30px;
  height: 30px;
  border-radius: 3px;
  border: 3px solid #ff4068;
  margin-right: 10px;
  text-align: center;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.checkbox > .box .fa {
  font-size: 20px;
  line-height: 24px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  /*opacity: 0;*/
  color: #ff4068;
  display: block;
}

.order_summary_price {
  font-size: 45px;
  font-weight: bold;
}

.fv-nip {
  font-size: 25px;
  font-family: Helvetica, sans-serif;
  color: #222;
  font-weight: 400;
}

.order-agreement__checkbox-center {
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 600px) {
  h1.mainTitle {
    font-size: 32px;
  }

  .order_summary-table tr td:first-child {
    padding-right: 10px;
  }
  .order_summary-table tr td:last-child {
    padding-left: 0;
  }

  .agreement-container {
    text-align: left;
  }

  .fv-nip {
    padding-left: 0;
    margin-top: 25px;
    font-size: 20px;
  }

  .order-agreement__label {
    font-size: 20px;
  }

  .order_summary_price {
    font-size: 30px;
  }

  .order-agreement__checkbox-center {
    width: 90%;
    margin: 0 auto;
  }
}

.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.btn-yellow {
  border-radius: 30px;
  background: #ff4068;
  font-size: 24px;
}
</style>
